<template>
  <div>
    <div :class="$style.home">
      <div :class="$style.title">权限列表</div>
      <a-table :columns="columns" :data-source="data" row-key="id" :pagination="false" />
    </div>
  </div>
</template>

<script>
import { roleService } from '@/service'

const service = new roleService()

export default {
  name: 'Home',
  data() {
    return {
      data: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '权限名称',
          dataIndex: 'name',
        },
        {
          title: '字段名称',
          dataIndex: 'id',
        },
        {
          title: '描述信息',
          dataIndex: 'describe',
        },
      ]
    },
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const { data } = await service.getTree()
      this.data = data.app.concat(data.resource).concat(data.system)
    },
  },
}
</script>

<style lang="less" module>
.home {
  background: #fff;
  padding: 20px;

  .title {
    margin-bottom: 20px;
    font-size: 16px;
  }
}
</style>
